import React, { useState, useContext, useEffect } from "react";

import {
  Button,
  Box,
  FormControl,
  FormGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import Form from "react-bootstrap/Form";
//  import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import { UserContext } from "../../contexts/userContext.js";
import { useSnackbar } from "notistack";
import axios from "axios";
import LoadingScreen from "../Utils/LoadingScreen.js";
import Login from "./Login.js";
import MainNav from "../MainNav";

function ForgotPassword() {
  // eslint-disable-next-line no-unused-vars
  const { setUser } = useContext(UserContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [password2, setPassword2] = useState("");
  const [password1, setPassword1] = useState("");
  const [username, setUsername] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [loginErrors, setLoginErrors] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const isMobile = useMediaQuery("(max-width:700px)");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleForgotPassword();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [password1, password2]);

  const handleForgotPassword = () => {
    setLoginErrors("");
    if (username === "") {
      setLoginErrors("Email can't be empty");
    } else {
      const BASE_URL = process.env.REACT_APP_BASE_URL;
      axios
        .post(
          `${BASE_URL}/api/forgot_password/`,
          { email: username.toLowerCase() },
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data["message"] == "bad_email") {
            setLoginErrors("We could not find a user with that email.");
          } else if (response.data["message"] == "success") {
            enqueueSnackbar("Password Changed Successfully!", {
              variant: "success",
            });
            setShowSuccess(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <MainNav />
      <Box
        sx={
          isMobile
            ? { width: "85%", margin: "auto" }
            : { width: "50%", margin: "auto" }
        }
      >
        {loading ? (
          <LoadingScreen />
        ) : showSuccess ? (
          <>
            <Typography variant="h4" sx={{ float: "left" }}>
              Email has been sent!
            </Typography>
            <Typography variant="subtitle1" sx={{ float: "left" }}>
              Please check you inbox including your spam folder. After you
              changed your password,
            </Typography>
          </>
        ) : (
          <>
            <>
              <FormGroup id="username">
                <FormControl sx={{ backgroundColor: "#dedede" }}>
                  <TextField
                    onChange={(event) => {
                      setUsername(event.target.value);
                    }}
                    type="text"
                    id="username"
                    label="Email"
                    name="username"
                  />
                </FormControl>
              </FormGroup>
              {loginErrors ? (
                <Typography
                  variant="subtitle1"
                  warning
                  sx={{ marginTop: "15px" }}
                >
                  {loginErrors}
                </Typography>
              ) : (
                ""
              )}
              <br />

              <Box>
                <Button
                  type="submit"
                  primary
                  variant="contained"
                  onClick={() => handleForgotPassword()}
                >
                  Send Email
                </Button>
              </Box>
            </>
          </>
        )}
      </Box>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      {showSuccess ? (
        <Box>
          <Login />
        </Box>
      ) : (
        ""
      )}
    </div>
  );
}

export default ForgotPassword;
