import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Box,
  FormControl,
  FormGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Login from "./Login.js";
import { UserContext } from "../../contexts/userContext.js";
import { logoutUser, signUpUser } from "../../auth/auth.js";

function SignUp() {
  const { setUser } = useContext(UserContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [dogName, setDogName] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [loginErrors, setLoginErrors] = useState("");
  const isMobile = useMediaQuery("(max-width:700px)");
  const history = useNavigate();

  const onSignUpFormSubmit = () => {
    setLoginErrors("");
    if (!isValidForm()) {
      return;
    }
    signUpUser(username.toLowerCase(), password, dogName)
      .then((data) => {
        if (data.error) {
          setLoginErrors(data.error);
        } else {
          setUser({ username: username });
          setShowLogin(true);
          enqueueSnackbar("Signup Successful!", {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        setLoginErrors("There was a problem signing up");
      });
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        onSignUpFormSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [username, password, dogName]);
  const isValidForm = () => {
    setLoginErrors("");
    if (username === "" || password === "" || password2 === "") {
      setLoginErrors("Email and password can't be empty");
      return false;
    }
    if (!username.includes("@") || !username.includes(".")) {
      setLoginErrors("That doesn't appear to be a valid email");
      return false;
    }
    if (password !== password2) {
      setLoginErrors("Those passwords don't match");

      return false;
    }
    return true;
  };

  // eslint-disable-next-line no-unused-vars
  const logout = () => {
    logoutUser();
    setUser(null);
  };

  return (
    <div>
      {showLogin ? (
        <Login />
      ) : (
        <Box
          sx={
            isMobile
              ? { width: "85%", margin: "auto" }
              : { width: "50%", margin: "auto" }
          }
        >
          <FormGroup id="username">
            <FormControl sx={{ backgroundColor: "#dedede" }}>
              <TextField
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
                type="text"
                id="username"
                label="Email"
                name="username"
              />
            </FormControl>
          </FormGroup>
          <br />
          <FormGroup id="dog_name">
            <FormControl sx={{ backgroundColor: "#dedede" }}>
              <TextField
                onChange={(event) => {
                  setDogName(event.target.value);
                }}
                type="text"
                id="dog_name"
                label="Pupper Name"
                name="dog_name"
              />
            </FormControl>
          </FormGroup>
          <br />
          <FormGroup id="password">
            <FormControl sx={{ backgroundColor: "#dedede" }}>
              <TextField
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                type="password"
                label="Password"
                id="password"
                name="password"
              />
            </FormControl>
          </FormGroup>
          <br />
          <FormGroup id="password">
            <FormControl sx={{ backgroundColor: "#dedede" }}>
              <TextField
                onChange={(event) => {
                  setPassword2(event.target.value);
                }}
                type="password"
                label="Retype Password"
                id="password2"
                name="password2"
              />
            </FormControl>
          </FormGroup>
          {loginErrors ? (
            <Typography variant="subtitle1" warning sx={{ marginTop: "15px" }}>
              {loginErrors}
            </Typography>
          ) : (
            ""
          )}
          <Button
            type="submit"
            primary
            variant="contained"
            sx={{ marginTop: "25px" }}
            onClick={() => onSignUpFormSubmit()}
          >
            Sign Up
          </Button>
        </Box>
      )}
    </div>
  );
}

export default SignUp;
