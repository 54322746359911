import React, { useState, useContext, useEffect } from "react";

import {
  Button,
  Box,
  FormControl,
  FormGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import Form from "react-bootstrap/Form";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { UserContext } from "../../contexts/userContext.js";
import axios from "axios";
import LoadingScreen from "../Utils/LoadingScreen.js";
import MainNav from "../MainNav";

function UpdateForgotPassword() {
  // eslint-disable-next-line no-unused-vars
  const { setUser, showForgotPassword, setShowForgotPassword } =
    useContext(UserContext);
  const [password2, setPassword2] = useState("");
  const [password1, setPassword1] = useState("");
  const [username, setUsername] = useState("");
  const [loginErrors, setLoginErrors] = useState("");
  const isMobile = useMediaQuery("(max-width:700px)");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const urlParams = new URLSearchParams(window.location.search);
  const key = urlParams.get("key");
  const email = decodeURIComponent(urlParams.get("email"));

  const onChangePasswordSubmit = () => {
    setLoading(true);
    if (!isValidForm()) {
      setLoading(false);
      return;
    }
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const payload = {
      key,
      email: encodeURIComponent(email).replace(/%20/g, "+"),
      password: password1,
    };
    axios
      .post(`${BASE_URL}/api/update_forgot_password/`, payload, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data["message"] == "success") {
          enqueueSnackbar("Password Changed Successfully!", {
            variant: "success",
          });
          history("/login");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        onChangePasswordSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [password1, password2]);

  const isValidForm = () => {
    setLoginErrors("");
    if (password1 === "" || password2 === "") {
      setLoginErrors("Passwords can't be empty.");
      return false;
    }
    if (password1 != password2) {
      setLoginErrors("Passwords don't match.");
      return false;
    }

    return true;
  };

  // eslint-disable-next-line no-unused-vars

  return (
    <div>
      <MainNav />
      <Box
        sx={
          isMobile
            ? { width: "85%", margin: "auto" }
            : { width: "50%", margin: "auto" }
        }
      >
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            <>
              <Typography variant="h4" sx={{ float: "left" }}>
                Reset your password:
              </Typography>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <FormGroup id="password1">
                <FormControl sx={{ backgroundColor: "#dedede" }}>
                  <TextField
                    onChange={(event) => {
                      setPassword1(event.target.value);
                    }}
                    type="password"
                    id="password1"
                    label="Password"
                    name="password1"
                  />
                </FormControl>
              </FormGroup>
              <br />
              <FormGroup id="password2">
                <FormControl sx={{ backgroundColor: "#dedede" }}>
                  <TextField
                    onChange={(event) => {
                      setPassword2(event.target.value);
                    }}
                    type="password"
                    label="Password (again)"
                    id="password2"
                    name="password2"
                  />
                </FormControl>
              </FormGroup>
              {loginErrors ? (
                <Typography
                  variant="subtitle1"
                  warning
                  sx={{ marginTop: "15px" }}
                >
                  {loginErrors}
                </Typography>
              ) : (
                ""
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "25px",
                }}
              >
                <Box sx={{ width: "33.3333%" }} />
                <Box
                  sx={{
                    width: "33.3333%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    primary
                    variant="contained"
                    // sx={{ marginTop: "25px" }}
                    onClick={() => onChangePasswordSubmit()}
                  >
                    Change Password
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: "33.3333%",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "right",
                    justifyContent: "right",
                  }}
                ></Box>
              </Box>
            </>
          </>
        )}
      </Box>
    </div>
  );
}

export default UpdateForgotPassword;
