import React, { useState, useContext, useEffect } from "react";

import {
  Button,
  Box,
  FormControl,
  FormGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import Form from "react-bootstrap/Form";
import { UserContext } from "../../contexts/userContext.js";
import { useNavigate } from "react-router-dom";
import { logoutUser, loginUser } from "../../auth/auth.js";
import axios from "axios";
import LoadingScreen from "../Utils/LoadingScreen.js";

function Login() {
  // eslint-disable-next-line no-unused-vars
  const { setUser } = useContext(UserContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginErrors, setLoginErrors] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const isMobile = useMediaQuery("(max-width:700px)");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  const onLoginFormSubmit = () => {
    setLoading(true);
    setLoginErrors("");
    if (!isValidForm()) {
      setLoading(false);
      return;
    }
    loginUser(username.toLowerCase(), password)
      .then((data) => {
        setUser({ username: username });
        history("/home");
      })
      .catch((error) => {
        console.log("error");
        setLoginErrors("That email or password could not be verified");
      });
    setLoading(false);
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        onLoginFormSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [username, password]);
  const handleForgotPassword = () => {
    setLoginErrors("");
    if (username === "") {
      setLoginErrors("Email can't be empty");
    } else {
      const BASE_URL = process.env.REACT_APP_BASE_URL;
      axios
        .post(
          `${BASE_URL}/api/forgot_password/`,
          { email: username },
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const isValidForm = () => {
    setLoginErrors("");
    if (username === "" || password === "") {
      setLoginErrors(
        "Email and password can't be empty. try Email: test@test.com, Password: test"
      );
      return false;
    }
    return true;
  };

  // eslint-disable-next-line no-unused-vars
  const logout = () => {
    logoutUser();
    setUser(null);
  };

  return (
    <div>
      <Box
        sx={
          isMobile
            ? { width: "85%", margin: "auto" }
            : { width: "50%", margin: "auto" }
        }
      >
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            <Typography variant="h4" sx={{ float: "left" }}>
              {showForgotPassword ? "Forgot Password?" : "Login"}
            </Typography>
            <br />
            <br />
            <br />
            <FormGroup id="username">
              <FormControl sx={{ backgroundColor: "#dedede" }}>
                <TextField
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                  type="text"
                  id="username"
                  label="Email"
                  name="username"
                />
              </FormControl>
            </FormGroup>
            <br />
            {showForgotPassword ? (
              <Box>
                <Button
                  type="submit"
                  primary
                  variant="contained"
                  onClick={() => handleForgotPassword()}
                >
                  Send Email
                </Button>
              </Box>
            ) : (
              <>
                <FormGroup id="password">
                  <FormControl sx={{ backgroundColor: "#dedede" }}>
                    <TextField
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                      type="password"
                      label="Password"
                      id="password"
                      name="password"
                    />
                  </FormControl>
                </FormGroup>
                {loginErrors ? (
                  <Typography
                    variant="subtitle1"
                    warning
                    sx={{ marginTop: "15px" }}
                  >
                    {loginErrors}
                  </Typography>
                ) : (
                  ""
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "25px",
                  }}
                >
                  <Box sx={{ width: "33.3333%" }} />
                  <Box
                    sx={{
                      width: "33.3333%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      primary
                      variant="contained"
                      // sx={{ marginTop: "25px" }}
                      onClick={() => onLoginFormSubmit()}
                    >
                      Login
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      width: "33.3333%",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "right",
                      justifyContent: "right",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        display: "flex",
                        textAlign: "right",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        //  setShowForgotPassword(true);
                        history("/forgot_password");
                      }}
                    >
                      Forgot Password?
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </div>
  );
}

export default Login;
