import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  Box,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { UserContext } from "../contexts/userContext.js";
import LoadingScreen from "../components/Utils/LoadingScreen.js";
import ChangePassword from "../components/Login/ChangePassword.js";
import { useNavigate, Navigate } from "react-router-dom";
import MainNav from "../components/MainNav";
import axios from "axios";
import ReactPlayer from "react-player";

function HomePage() {
  const { user, showAccount, setProductID, productID, ngrokURL, setNgrokURL } =
    useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [productIDForm, setProductIDForm] = useState("");
  const [errors, setErrors] = useState("");
  const history = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isMobile = useMediaQuery("(max-width:700px)");
  const [videoURLs, setVideoURLs] = useState([]);
  const [celeryID, setCeleryID] = useState("");

  useEffect(() => {
    setLoading(true);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    axios
      .get(`${BASE_URL}/api/get_customer_details/`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
        },
      })
      .then((response) => {
        setProductID(response.data["product_id"]);
        setNgrokURL(response.data["ngrok_url"]);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${BASE_URL}/product/my_videos/`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
        },
      })
      .then((response) => {
        setVideoURLs(response.data["presignedURLs"]);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  }, []);
  const handleRegisterProduct = () => {
    setLoading(true);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const payload = { product_id: productIDForm.toUpperCase() };
    axios
      .post(`${BASE_URL}/api/add_product/`, payload, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
        },
      })
      .then((response) => {
        setProductID(response.data["product_id"]);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };
  const handleDispenseTreat = () => {
    setLoading(true);
    const url = `${ngrokURL}/servo/dispense_treat/${productID}/`;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .then((response) => {
        enqueueSnackbar("Treat Dispensed Successfully!", {
          variant: "success",
        });
        setCeleryID(response.data["celery_id"]);
        setTimeout(() => pollBackend(), 1000);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };
  if (!user) {
    return <Navigate to="/" replace />;
  }
  //celery_state == "SUCCESS"
  const pollBackend = () => {
    if (celeryID) {
      axios
        .get(`${ngrokURL}/videos/check_video_status/${celeryID}/`, {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        })
        .then((res) => {
          console.log("the celery status is", res.data.celery_status);
          //  setTimeout(() => pollBackend(), pollingInterval)
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div>
      <MainNav />
      <Box
        sx={
          isMobile
            ? { width: "85%", margin: "auto" }
            : { width: "50%", margin: "auto" }
        }
      >
        {loading ? (
          <LoadingScreen />
        ) : showAccount ? (
          <ChangePassword />
        ) : (
          <div>
            <Typography variant="h4" sx={{ float: "left" }}>
              Home
            </Typography>
            <br />
            <br />
            <br />

            {productID ? (
              <Button
                type="submit"
                primary
                variant="contained"
                //  sx={{ float: "right" }}
                onClick={() => handleDispenseTreat()}
              >
                Dispense Treat
              </Button>
            ) : (
              <>
                <Typography variant="subtitle1" sx={{ float: "left" }}>
                  Please find your product id on the back of your doggy treat
                  dispense, and register it here:
                </Typography>
                <br />
                <br />
                <br />
                <br />
                <br />
                <FormGroup id="productID">
                  <FormControl sx={{ backgroundColor: "#dedede" }}>
                    <TextField
                      onChange={(event) => {
                        setProductIDForm(event.target.value);
                      }}
                      type="text"
                      id="productID"
                      label="Product ID"
                      name="productID"
                    />
                  </FormControl>
                </FormGroup>
                {errors ? (
                  <Typography
                    variant="subtitle1"
                    warning
                    sx={{ marginTop: "15px" }}
                  >
                    {errors}
                  </Typography>
                ) : (
                  ""
                )}
                <br />
                <br />

                <Button
                  type="submit"
                  primary
                  variant="contained"
                  sx={{ float: "right" }}
                  onClick={() => handleRegisterProduct()}
                >
                  Register Product
                </Button>
              </>
            )}
          </div>
        )}
        <br />
        <br />
        <br />

        {videoURLs
          ? videoURLs.map((vid) => (
              <ReactPlayer
                url={vid}
                width="100%"
                height="100%"
                controls="true"
              />
            ))
          : ""}
      </Box>
    </div>
  );
}

export default HomePage;
