import axios from "axios";
import jwtDecode from "jwt-decode";
// this base url will be change based on
// if you need to point to production.
const BASE_URL = process.env.REACT_APP_BASE_URL;
const ACCESS_TOKEN = process.env.REACT_APP_BASE_URL;
const REFRESH_TOKEN = process.env.REACT_APP_BASE_URL;

let axios_request = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

const loginUser = (username, password) => {
  const loginBody = { email: username, password: password };

  return axios
    .post(`${BASE_URL}/api/token/both/`, loginBody, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
    .then((response) => {
      localStorage.setItem("ACCESS_TOKEN", response.data.access);
      localStorage.setItem("REFRESH_TOKEN", response.data.refresh);
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.access}`;
      localStorage.setItem("USERNAME", username);
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.log("the error response is ", error);
      return Promise.reject(error);
    });
};

const changePassword = (password) => {
  const changePasswordBody = { password: password };

  return axios
    .post(`${BASE_URL}/api/update_password/`, changePasswordBody, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.log("the error response is ", error);
      return Promise.reject(error);
    });
};

const signUpUser = (username, password, dog_name) => {
  const signUpBody = {
    email: username,
    password: password,
    dog_name: dog_name,
  };

  return axios
    .post(`${BASE_URL}/api/sign_up/`, signUpBody, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.log("the error response is ", error);
      return Promise.reject(error);
    });
};

const refreshToken = () => {
  const refreshBody = {
    refresh: localStorage.getItem("REFRESH_TOKEN"),
  };
  return axios
    .post(`${BASE_URL}/api/token/access/`, refreshBody, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
    .then((response) => {
      localStorage.setItem("ACCESS_TOKEN", response.data.access);
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.access}`;
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const isCorrectRefreshError = (status) => {
  return status === 401;
};

const authRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
    "Content-Type": "application/json",
  },
});
authRequest.interceptors.response.use(
  (response) => response, // this is for all successful requests.
  (error) => {
    //handle the request
    return errorInterceptor(error);
  }
);

const errorInterceptor = (error) => {
  const originalRequest = error.config;
  const status = error.response.status;
  if (isCorrectRefreshError(status)) {
    return refreshToken()
      .then((data) => {
        const headerAuthorization = `Bearer ${localStorage.getItem(
          "ACCESS_TOKEN"
        )}`;
        authRequest.defaults.headers["Authorization"] = headerAuthorization;
        originalRequest.headers["Authorization"] = headerAuthorization;
        return authRequest(originalRequest);
      })
      .catch((error) => {
        // if token refresh fails, logout the user to avoid potential security risks.
        logoutUser();
        return Promise.reject(error);
      });
  }
  return Promise.reject(error);
};

const logoutUser = () => {
  localStorage.removeItem("ACCESS_TOKEN");
  localStorage.removeItem("REFRESH_TOKEN");
  localStorage.removeItem("USERNAME");
  authRequest.defaults.headers["Authorization"] = "";
};

const initialize = (user, setUser) => {
  try {
    if (!user) {
      try {
        setUser(localStorage.getItem("USERNAME"));
      } catch (err) {
        return;
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export {
  axios_request,
  loginUser,
  logoutUser,
  signUpUser,
  refreshToken,
  initialize,
  authRequest,
  errorInterceptor,
  BASE_URL,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  changePassword,
};
