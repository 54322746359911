import React, { useState } from "react";
import Login from "../components/Login/Login.js";
import SignUp from "../components/Login/SignUp.js";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import MainNav from "../components/MainNav";
function LoginPage() {
  const [showSignUp, setShowSignUp] = useState(false);
  return (
    <>
      <MainNav />
      {showSignUp ? (
        <>
          <h1>Sign Up</h1>
          <SignUp />
        </>
      ) : (
        <div>
          <Login />
          <br />
          <Typography variant="subtitle1">Don't have an account?</Typography>
          <br />
          <Button
            onClick={() => setShowSignUp(true)}
            size="xlarge"
            primary
            variant="contained"
          >
            Sign Up
          </Button>
        </div>
      )}
    </>
  );
}

export default LoginPage;
