import React, { useState, useContext, useEffect } from "react";

import {
  Button,
  Box,
  FormControl,
  FormGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import Form from "react-bootstrap/Form";
//  import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { UserContext } from "../../contexts/userContext.js";
import { logoutUser, loginUser, changePassword } from "../../auth/auth.js";
import axios from "axios";
import LoadingScreen from "../Utils/LoadingScreen.js";

function ChangePassword() {
  // eslint-disable-next-line no-unused-vars
  const {
    setUser,
    showForgotPassword,
    setShowForgotPassword,
    loading,
    setLoading,
    anchorEl,
  } = useContext(UserContext);
  const [password2, setPassword2] = useState("");
  const [password1, setPassword1] = useState("");
  const [loginErrors, setLoginErrors] = useState("");
  const isMobile = useMediaQuery("(max-width:700px)");
  const history = useNavigate();

  const onChangePasswordSubmit = () => {
    setLoading(true);
    setLoginErrors("");
    if (!isValidForm()) {
      setLoading(false);
      return;
    }
    console.log(password1);
    changePassword(password1)
      .then((data) => {
        console.log(data);
        // setUser({ username: username });
      })
      .catch((error) => {
        console.log("error");
        setLoginErrors("That email or password could not be verified");
      });
    setLoading(false);
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        changePassword();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [password1, password2]);

  const isValidForm = () => {
    setLoginErrors("");
    if (password1 === "" || password2 === "") {
      setLoginErrors("Passwords can't be empty.");
      return false;
    }
    if (password1 != password2) {
      setLoginErrors("Passwords don't match.");
      return false;
    }

    return true;
  };

  // eslint-disable-next-line no-unused-vars
  const logout = () => {
    logoutUser();
    setUser(null);
  };

  return (
    <div>
      <Box
        sx={
          isMobile
            ? { width: "85%", margin: "auto" }
            : { width: "50%", margin: "auto" }
        }
      >
        {loading || anchorEl ? (
          <LoadingScreen />
        ) : (
          <>
            <>
              <FormGroup id="password1">
                <FormControl sx={{ backgroundColor: "#dedede" }}>
                  <TextField
                    onChange={(event) => {
                      setPassword1(event.target.value);
                    }}
                    type="password"
                    id="password1"
                    label="Password"
                    name="password1"
                  />
                </FormControl>
              </FormGroup>
              <br />
              <FormGroup id="password2">
                <FormControl sx={{ backgroundColor: "#dedede" }}>
                  <TextField
                    onChange={(event) => {
                      setPassword2(event.target.value);
                    }}
                    type="password"
                    label="Password (again)"
                    id="password2"
                    name="password2"
                  />
                </FormControl>
              </FormGroup>
              {loginErrors ? (
                <Typography
                  variant="subtitle1"
                  warning
                  sx={{ marginTop: "15px" }}
                >
                  {loginErrors}
                </Typography>
              ) : (
                ""
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "25px",
                }}
              >
                <Box sx={{ width: "33.3333%" }} />
                <Box
                  sx={{
                    width: "33.3333%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    primary
                    variant="contained"
                    // sx={{ marginTop: "25px" }}
                    onClick={() => onChangePasswordSubmit()}
                  >
                    Change Password
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: "33.3333%",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "right",
                    justifyContent: "right",
                  }}
                ></Box>
              </Box>
            </>
          </>
        )}
      </Box>
    </div>
  );
}

export default ChangePassword;
