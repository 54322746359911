import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { UserContext } from "../../contexts/userContext.js";
import "./App.css";
import Container from "react-bootstrap/Container";

import LoginPage from "../../pages/LoginPage.js";
import HomePage from "../../pages/HomePage.js";
import ChangePassword from "../Login/ChangePassword.js";
import ForgotPassword from "../Login/ForgotPassword.js";
import UpdateForgotPassword from "../Login/UpdateForgotPassword.js";
import SignUp from "../Login/SignUp.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { initialize } from "../../auth/auth";
import LoadingScreen from "../Utils/LoadingScreen.js";
import { SnackbarProvider } from "notistack";

function App() {
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [signUp, setSignUp] = useState(false);
  const [loginErrors, setLoginErrors] = useState("");
  const [showAccount, setShowAccount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [productID, setProductID] = useState("");
  const [ngrokURL, setNgrokURL] = useState("");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#F24C4C",
      },
      secondary: {
        main: "#EC9B3B",
      },
    },
  });
  useEffect(() => {
    setLoading(true);
    initialize(user, setUser);
    setLoading(false);
  }, []);
  return (
    <div className="App">
      <UserContext.Provider
        value={{
          user,
          setUser,
          userDetails,
          setUserDetails,
          signUp,
          setSignUp,
          loginErrors,
          setLoginErrors,
          showAccount,
          setShowAccount,
          showForgotPassword,
          setShowForgotPassword,
          productID,
          setProductID,
          ngrokURL,
          setNgrokURL,
          loading,
          setLoading,
        }}
      >
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            // TransitionComponent={Slide}
          >
            {/* <Suspense fallback={<></>}> */}
            <Container>
              {/* <MainNav /> */}
              {loading ? (
                <LoadingScreen />
              ) : (
                <Router>
                  <Routes>
                    <Route path="/" exact element={<LoginPage />} />
                    <Route path="/home" exact element={<HomePage />} /> */}
                    <Route path="/login" exact element={<LoginPage />} />
                    <Route path="/account" exact element={<ChangePassword />} />
                    <Route path="/signup" exact element={<SignUp />} />
                    <Route path="/loading" exact element={<LoadingScreen />} />
                    <Route
                      path="/forgot_password"
                      exact
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/update_forgot_password"
                      exact
                      element={<UpdateForgotPassword />}
                    />
                  </Routes>
                </Router>
              )}
            </Container>

            {/* <Route path="/">
                <LoginPage />
              </Route> */}
            {/* </Suspense> */}
          </SnackbarProvider>
        </ThemeProvider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
