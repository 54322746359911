import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/userContext.js";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { logoutUser } from "../auth/auth.js";
import { useNavigate } from "react-router-dom";
export default function MainNav() {
  const { user, setUser, setShowAccount, setLoading } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState();
  const [id, setID] = useState();
  const history = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  useEffect(() => {
    setOpen(Boolean(anchorEl));
    setID(Boolean(anchorEl) ? "simple-popover" : undefined);
  }, [anchorEl]);

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          cursor: "auto",
          backgroundColor: "transparent",
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Box
          onClick={() => {
            setShowAccount(() => false);
            history("/");
          }}
        >
          <img
            src="/tedi_avatar2.png"
            style={{
              borderRadius: "50%",
              height: "6rem",
              width: "7rem",
              cursor: "pointer",
              paddingLeft: "1rem",
            }}
            alt="Tedi Treats - Home"
          />
        </Box>
        {user ? (
          <>
            <Box onClick={handleClick} aria-describedby={id}>
              <img
                src="/default_user.png"
                style={{
                  height: "5rem",
                  width: "5rem",
                  cursor: "pointer",
                  paddingRight: "1rem",
                }}
                alt="May Account"
              />
            </Box>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              //  options={delay: {"hide": 0 }}
            >
              <Box
                onClick={() => {
                  setLoading(true);
                  handleClose();
                  setShowAccount(true);
                  setLoading(false);
                }}
                sx={{ cursor: "pointer" }}
              >
                <Typography sx={{ p: 2 }}>Account</Typography>
              </Box>
              <Box
                onClick={() => {
                  logoutUser();
                  setUser(null);
                  setAnchorEl(null);
                  history("/");
                }}
                sx={{ cursor: "pointer" }}
              >
                <Typography sx={{ p: 2 }}>Logout</Typography>
              </Box>
            </Popover>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
